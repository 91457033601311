<template>
  <v-card class="margin-y-4 border-radius-12">
    <v-card-text class="padding-y-5">
      <v-layout row>
        <v-layout column cols="12" class="padding-b-2">
          <h2>
            {{ title }}
          </h2>
        </v-layout>
      </v-layout>
      <p v-if="hasAddedAmenities">
        Added extras
      </p>
      <v-layout row wrap>
        <v-flex
          v-for="(amenity, amenityIndex) in addedAmenities"
          :key="`amenity-${amenity.id}-${amenityIndex}`"
          class="padding-y-2 padding-r-2"
          xs12
          sm6
        >
          <v-layout align-center class="d-inline-flex">
            <CRIcon
              class="cursor-default margin-r-2"
              color="primary"
              view-box="0 0 24 24"
              width="20px"
              height="20px"
            >
              {{ amenity.icon }}
            </CRIcon>
            <span>
              {{ amenity.title }}
            </span>
          </v-layout>
        </v-flex>
      </v-layout>
      <div v-if="hasRequestedAmenities" class="margin-t-2">
        <p>Requested Amenities</p>
        <v-layout row wrap>
          <v-flex
            v-for="(amenity, amenityIndex) in requestedAmenities"
            :key="`amenity-${amenity.id}-${amenityIndex}`"
            xs12
            sm6
            class="padding-y-2 padding-r-2"
          >
            <v-layout align-center class="d-inline-flex">
              <CRIcon
                class="cursor-default margin-r-2"
                color="primary"
                view-box="0 0 24 24"
                width="20px"
                height="20px"
              >
                {{ amenity.icon }}
              </CRIcon>
              <span>
                {{ amenity.title }}
              </span>
            </v-layout>
          </v-flex>
        </v-layout>
        <p v-if="isCharterBus" class="font-14">
          Bathroom, luggage storage, outlets, and TV screens are available on most
          Charter Buses.
        </p>
      </div>
      <div v-if="hasAmenityNotes" class="margin-t-2">
        <a class="font-12" @click="isModalOpen = true">
          See amenities notes
        </a>
      </div>
    </v-card-text>
    <TripAmenityCardModal v-model="isModalOpen" :amenities="amenitiesWithNotes" />
  </v-card>
</template>

<script>
import { VehicleTypeId, paidAmenityTypeIds } from '@/utils/enum'
import { CHARTER_BUS_INCLUDED_AMENITY_IDS } from '@/utils/amenity'
import TripAmenityCardModal from '@/components/charterup/TripAmenityCardModal.vue'

export default {
  name: 'TripAmenityCard',
  components: {
    TripAmenityCardModal,
  },
  props: {
    isSelfServe: {
      type: Boolean,
      required: true,
    },
    vehicleTypeId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    amenities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  computed: {
    additionalAmenities() {
      if (this.isCharterBus) {
        return this.amenities.filter((amenity) => !CHARTER_BUS_INCLUDED_AMENITY_IDS.includes(amenity.id))
      }
      return this.amenities
    },
    addedAmenities() {
      if (!this.isSelfServe) {
        return []
      }
      return this.additionalAmenities.filter((amenity) => Object.values(paidAmenityTypeIds).includes(amenity.id))
    },
    hasAddedAmenities() {
      return this.addedAmenities.length > 0
    },
    requestedAmenities() {
      return this.additionalAmenities.filter((amenity) => !this.addedAmenities.includes(amenity))
    },
    hasRequestedAmenities() {
      return this.requestedAmenities.length > 0
    },
    amenitiesWithNotes() {
      return this.additionalAmenities.filter((amenity) => amenity.note?.note?.length > 0)
    },
    hasAmenityNotes() {
      return this.amenitiesWithNotes.length > 0
    },
    isCharterBus() {
      return this.vehicleTypeId === VehicleTypeId.CharterBus
    }
  },
}
</script>
