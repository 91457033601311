<template>
  <div>
    <TripAmenityCard
      v-for="(vehicleType, index) in amenitiesByVehicleType"
      :key="`vehicle-amenity-card-${vehicleType.id}-${index}`"
      :is-self-serve="isSelfServe"
      :vehicle-type-id="vehicleType.id"
      :title="vehicleType.title"
      :amenities="vehicleType.amenities"
    />
  </div>
</template>

<script>
import TripAmenityCard from '@/components/charterup/TripAmenityCard.vue'
import { getAvailableAmenitiesByVehicleTypeId, AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS } from '@/utils/amenity'
import { VehicleTypeId } from '@/utils/enum'

export default {
  name: 'TripAmenitiesCards',
  components: {
    TripAmenityCard,
  },
  props: {
    isSelfServe: {
      type: Boolean,
      required: true,
    },
    amenities: {
      type: Array,
      required: true,
    },
    vehicles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    amenitiesByVehicleType() {
      const vehicleTypes = [
        ...new Set(
          this.vehicles
            .filter((vehicle) => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(vehicle.vehicleType?.key))
            .map(v => { return { id: v.vehicleTypeId, label: v.vehicleType?.label } } )
        )
      ]
      return vehicleTypes.map(vt => {
        const amenities = getAvailableAmenitiesByVehicleTypeId(
          this.amenities,
          vt.id
        )
        return { id: vt.id, title: `${vt.label} Amenities`, amenities }
      })
    }
  },
}
</script>
