
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityType } from '@/models/dto/Amenity'

@Component({})
export default class TripAmenityCardModal extends Vue {
  @Prop({ type: Array, required: true }) readonly amenities!: AmenityType[]
  @Prop({ type: Boolean, required: true }) readonly value!: boolean
}

